<template>
  <v-card class="fill-height d-flex flex-column" >
    <v-card-title class="border-bottom">
      <span class="font-extrabold text-lg">{{ title }}</span>
      <v-spacer></v-spacer>
      <v-tooltip
          bottom
          v-if="
              checkDeletePermission($modules.marketing.sms.slug) &&
              status_id == 1"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="deleteMessage" class="" x-small>
            <DeleteIcon/>
          </v-btn>
        </template>
        <span>Delete</span>
      </v-tooltip>
      <v-tooltip
        bottom
        v-if="
          checkWritePermission($modules.marketing[type].slug) && status_id == 1
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="edit" x-small>
            <EditIcon/>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text
      v-if="type == 'sms'"
      style="max-height: 120px"
      class="overflow-y-auto overflow-x-hidden headline no-background mt-3"
    >
      "{{ message }}"
    </v-card-text>
    <v-card-text
      v-else
      style="max-height: 120px"
      class="overflow-y-auto overflow-x-hidden  mt-3"
      v-html="message"
    >
    </v-card-text>
    <v-card-text
      style="max-height: 90px"
      class="overflow-y-auto overflow-x-hidden pl-4 pr-4 border-bottom"
    >
      <v-chip v-if="name_filter" class="mr-2 mt-1" small
        >Name: {{ name_filter }}</v-chip
      >
      <v-chip v-if="email_filter" class="mr-2 mt-1" small
        >Email: {{ email_filter }}</v-chip
      >
      <v-chip v-if="mobile_filter" class="mr-2 mt-1" small
        >Mobile: {{ mobile_filter }}</v-chip
      >
      <v-chip v-if="customer_type" class="mr-2 mt-1" small
        >Customer Type:
        {{ customer_type == "i" ? "Individual" : "Corporate" }}</v-chip
      >
      <!-- <v-chip v-if="to_age && from_age" class="mr-2 mt-1" small
        >Age: {{ from_age }}-{{ to_age }}</v-chip
      > -->
      <v-chip v-if="interest" class="mr-2 mt-1" small
        >Interests:
        {{
          interest == "s" ? "Sports" : interest == "C" ? "Class" : "Events"
        }}</v-chip
      >
      <v-chip v-if="nationalities.length > 0" class="mr-2 mt-1" small
        >Nationality:
        <span class="d-inline-block text-truncate" style="max-width: 120px"
          ><span
            class="ml-1"
            v-for="nation in nationalities"
            :key="nation.id"
            >{{ nation.country.name }}</span
          ></span
        >
      </v-chip>
      <v-chip v-if="interested_categories.length > 0" class="mr-2 mt-1" small
        >Categories:
        <span class="d-inline-block text-truncate" style="max-width: 120px"
          ><span
            class="ml-1"
            v-for="category in interested_categories"
            :key="category.id"
            >{{ category.category.name }}</span
          ></span
        ></v-chip
      >
      <v-chip v-if="selected_memberships.length > 0" class="mr-2 mt-1" small
        >Memberships:
        <span class="d-inline-block text-truncate" style="max-width: 120px"
          ><span
            class="ml-1"
            v-for="membership in selected_memberships"
            :key="membership.id"
            >{{ membership.membership.name }}</span
          ></span
        ></v-chip
      >
    </v-card-text>
    <v-spacer></v-spacer>
    <v-card-actions>
      <v-row style="color: black ; font-weight: 500">
        <div class="col-md-12">
          <div class="d-flex justify-space-between align-center">
            <div class="d-flex align-center gap-x-2">
              <ClockIcon/>
              <div class="subheading  black--text">{{ schedule_date }}</div>
            </div>

            <div class="d-flex align-center gap-x-2" style="cursor: pointer"   @click="$emit('get-sms-logs', id)">
              <UsersMultipleIcon/>
              <div class="subheading mr-2 black--text">{{ marketing_customers.length}}</div>
            </div>

          </div>
        </div>




      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import UsersMultipleIcon from '@/assets/images/misc/user-multiple.svg';
import EditIcon from '@/assets/images/misc/edit-icon.svg';
import DeleteIcon from '@/assets/images/misc/delete-icon.svg';
import ClockIcon from '@/assets/images/misc/clock-icon.svg';

export default {
  components:{
    UsersMultipleIcon,
    EditIcon,
    DeleteIcon,
    ClockIcon
  },
  props: {
    title: { type: String, default: "" },
    message: { type: String, default: "" },
    type: { type: String, default: "sms" },
    schedule_date: { type: String, default: "2020-12-12 22:45:23" },
    id: { type: Number, default: 1 },
    status_id: { type: Number, default: 0 },
    marketing_customers: { type: Array, default: null },
    message_type: { type: String, default: "0" },
    from_age: { type: Number, default: 0 },
    interest: { type: String, default: "" },
    name_filter: { type: String, default: "" },
    email_filter: { type: String, default: "" },
    mobile_filter: { type: String, default: "" },
    customer_type: { type: String, default: "" },
    nationalities: {
      type: Array,
      default: () => {
        return [];
      },
    },
    interested_categories: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selected_memberships: {
      type: Array,
      default: () => {
        return [];
      },
    },
    year: { type: Number, default: null },
    yearIndex: { type: Number, default: null },
    monthName: { type: String, default: "" },
    monthIndex: { type: Number, default: null },
  },

  methods: {
    edit() {
      this.$router.push({
        name: "EditSMSEmailMarketing",
        params: { type: this.type, id: this.id },
      });
    },

    deleteMessage() {
      this.$emit("delete", {
        id: this.id,
        year: this.year,
        yearIndex: this.yearIndex,
        monthName: this.monthName,
        monthIndex: this.monthIndex,
      });
    },
  },
};
</script>

<style>

</style>
