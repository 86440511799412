<template>
  <v-container  fluid>

    <v-row>
      <v-col class="ml-2" lg="2" sm="3">
        <v-btn class="back-button" elevation="0" @click="goToMarketing" text>
          <SvgIcon text="Back">
            <template v-slot:icon>
              <BackIcon/>
            </template>
          </SvgIcon>
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col lg="4" sm="4" style="text-align: center">
        <v-btn-toggle
            borderless
            class="q-tabs"
            mandatory
            tile
            v-model="toggle_mode"
        >
          <v-btn value="scheduled">Scheduled</v-btn>

          <v-btn value="automated">Automated</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="text-lg-right" style="float: right" lg="2" sm="4">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="checkWritePermission($modules.marketing[type].slug)"
                v-bind="attrs"
                v-on="on"
                class="mr-6 bg-blue text-white"
                @click="gotoAddForm"
                dark
                height="45"
            >
              {{ type.toUpperCase() }}
              <v-icon right dark>mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          Add
        </v-tooltip>
      </v-col>
    </v-row>

    <div class="d-flex gap-x-4 p-y-8">
      <v-btn
          v-for="(yr,yi) in timeLineData"
          :key="yi"
          :class="{'active':yr.year === year.year}" style="height: 30px"
          class="q-btn-secondary-outlined"
          outlined
          @click="year = {...yr,status:true}"
      >
        {{ yr.year }}
      </v-btn>
    </div>
    <template v-if="timeLineData.length>0">
      <v-row v-if="year">
        <v-expansion-panels
            v-model="year.tabs[toggle_mode]"
            accordion
            flat
            hover
            light
            multiple
            tile
        >
          <template
              v-if="typeof year[toggle_mode] != 'undefined' && year.status == true"
          >
            <template v-for="(month, mi) in year[toggle_mode].months">
              <v-expansion-panel
                  :key="mi"
                  @change="getMonthWiseData(year.year, year.yearIndex, month, mi)"
                  class="m-b-8"
              >
                <v-expansion-panel-header class=" m-b-4 px-2 bg-white">
                  <span class="event-month-name">{{ month }}</span>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-row >
                    <v-col
                        v-for="message in year[toggle_mode][month]"
                        :key="`${message.id}-1`"
                        lg="4"
                    >
                      <message-card
                          v-bind="message"
                          :year="parseInt(year.year)"
                          :yearIndex="year.yearIndex"
                          :monthName="month"
                          :monthIndex="mi"
                          @get-sms-logs="getRecipientLogs"
                          @delete="deleteConfirmation"
                      />
                    </v-col>

                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </template>
          <template v-else-if="year.status">
            <h3>
              No
              {{ toggle_mode }}
              {{this.type}} in {{ year.year }}
              {{ nameFilter ? `for search ' ${nameFilter}'` : "" }}
            </h3>
          </template>
        </v-expansion-panels>
      </v-row>
    </template>
    <v-row v-else>
      <h3 class="text-center">
        No marketing {{this.type}}  found
      </h3>
    </v-row>

    <v-dialog v-model="logDialoge" scrollable width="80%">
      <v-card>


        <v-card-text  class="border-bottom mb-3">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold" :text="`${this.type.toUpperCase()} Recipient Log`" style="color: black" >
                </SvgIcon>
                <v-btn  fab x-small class="shadow-0" @click="logDialoge = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <v-row class="mt-6">
            <table  class="table border-collapse text-center">
              <thead>
              <tr class="opacity-70 tr-neon tr-rounded text-center">
                <th>Name</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>Gender</th>
                <th>Message</th>
                <th>Nationality</th>
                <th>Status</th>
              </tr>
              </thead>

              <tr v-for="data in logData" :key="data.id" class="text-center">
                <td>{{ data.name }}</td>
                <td>{{ data.mobile }}</td>
                <td>{{ data.email }}</td>
                <td>{{ data.gender }}</td>
                <td>{{ data.message }}</td>
                <td>
                  {{ data.country }}
                </td>
                <td>
                  {{
                    data.status_id == 1
                        ? "Scheduled"
                        : data.status_id == 12
                            ? "Delivered"
                            : "Failed"
                  }}
                </td>
              </tr>
            </table>
          </v-row>
          <v-pagination
              class="mt-3 new-pagination"
              v-model="page"
              :length="totalPages"
          ></v-pagination>
         </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="ma-2 "
              text
              @click="logDialoge = false"
          >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>

</template>

<script>
import ConfirmModel from "@/components/Confirmation/ConfirmModel";
import MessageCard from "@/components/Marketing/SMSMailCard.vue";
import moment from "moment";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import BackIcon from "@/assets/images/misc/back-icon.svg";
export default {
  components: {
    MessageCard,
    ConfirmModel,
    SvgIcon,
    BackIcon

  },
  mounted() {
    if (typeof this.$route.params.type != "undefined") {
      this.type = this.$route.params.type;
    }
    this.getTimeLineDataList(this.currentYear, true);
  },
  data() {
    return {

      type: "sms",
      toggle_mode: "schedule",
      logDialoge: false,
      timeLineData: [],
      currentYear: Number(moment().format("YYYY")),
      currentMonth: Number(moment().format("M")) - 1,
      logData: [],
      page: 1,
      totalPages: 1,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      id: null,
      yearInfo:null,
      year: null,
      nameFilter: null,
      search: "",


    };
  },

  watch: {
    page: {
      handler() {
        this.getRecipientLogs(this.id);
      },
    },
  },
  methods: {
    goToMarketing() {
      this.$router.push({ name: "Marketing" }, () => {});
    },
    gotoAddForm() {
      this.$router.push({
        name: "AddSMSEmailMarketing",
        params: { type: this.type },
      });
    },
    getTimeLineDataList(year, initial) {
      this.showLoader("Loading");
      let url = "venues/marketing/timeline?year=" + year;
      if (initial == true) {
        url += "&initial=1&type=" + this.type;
      }
      this.$http
          .get(url)
          .then((response) => {
            if (response.status == 200) {
              if (initial == true) {
                this.timeLineData = response.data.data;
                for (let index = 0; index < this.timeLineData.length; index++) {
                  this.timeLineData[index].tabs = {};
                  this.timeLineData[index].tabs.scheduled = [];
                  this.timeLineData[index].tabs.automated = [];
                  this.timeLineData[index].yearIndex = index;
                }
                let index = this.timeLineData.findIndex(
                    (item) => item.active != null
                );
                if (index != -1) {
                  let data = this.timeLineData[index];
                  this.getMonthWiseData(
                      data.year,
                      index,
                      data.active.months[0],
                      0
                  );
                }
                this.year = this.timeLineData[0];
                this.year.status = true;
              } else {
                let data = response.data.data;
                data.tabs = {};
                data.tabs.scheduled = [];
                data.tabs.automated = [];

                data.yearIndex = this.timeLineData.length;
                this.timeLineData.push(data);
              }
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    getMonthWiseData(currentYear, yearIndex, monthName, monthIndex, refresh) {
      console.log(currentYear, yearIndex, monthName, monthIndex, refresh)
      if (refresh == null) {
        let checkOpenTab = this.timeLineData[yearIndex].tabs[
            this.toggle_mode
            ].findIndex((index) => index == monthIndex);
        if (checkOpenTab != -1) {
          this.timeLineData[yearIndex].tabs[this.toggle_mode].slice(
              checkOpenTab,
              1
          );
          return;
        }
      }
      this.showLoader("Loading");
      let month = moment().month(monthName).format("M");
      this.$http
          .get(
              "venues/marketing/messages?year=" +
              currentYear +
              "&month=" +
              month +
              "&type=" +
              this.type +
              "&message_type=" +
              this.toggle_mode
          )
          .then((response) => {
            if (response.status == 200) {
              if (!this.timeLineData[yearIndex][this.toggle_mode]) {
                this.timeLineData[yearIndex][this.toggle_mode] = {};
              }
              this.timeLineData[yearIndex][this.toggle_mode][monthName] = [];
              this.timeLineData[yearIndex][this.toggle_mode][monthName] =
                  response.data.data;
              let checkOpenTab = this.timeLineData[yearIndex].tabs[
                  this.toggle_mode
                  ].findIndex((index) => index == monthIndex);
              if (checkOpenTab == -1) {
                this.timeLineData[yearIndex].tabs[this.toggle_mode].push(
                    monthIndex
                );
              }
              this.$forceUpdate();
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    getRecipientLogs(id) {
      this.id = id;
      this.$http
          .get("venues/marketing/message/logs/" + id + "?page=" + this.page)
          .then((response) => {
            if (response.status == 200) {
              this.logData = response.data.data;
              this.totalPages = response.data.totalPages;
              this.logDialoge = true;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    deleteConfirmation(data) {
      this.confirmModel = {
        id: data.id,
        title: "Do you want to delete this message?",
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "delete",
        data: data,
      };
    },
    confirmActions(data) {
      if (data.type == "delete") {
        this.deleteMessage(data.data);
      }
    },
    deleteMessage(data) {
      this.$http
          .put("venues/marketing/" + data.id + "/status")
          .then((response) => {
            if (response.status == 200) {
              this.confirmModel.id = null;
              this.getMonthWiseData(
                  data.year,
                  data.yearIndex,
                  data.monthName,
                  data.monthIndex,
                  true
              );
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
  },
};
</script>

<style scoped>
.v-expansion-panel-header--active {
  color: #00b0af;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent;
}
.status_button_navigation .v-btn {
  padding: 0;
  height: 30px;
  min-width: 54px;
  font-size: 0.7rem;
}
.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: none;
}
.month_names {
  background-color: rgb(1, 78, 97);
  padding: 6px 8px;
  border-radius: 5px;
  color: white;
}
.v-expansion-panel-header--active .month_names {
  background-color: rgb(4, 148, 184);
}
</style>
